import styles from "./Header.module.scss";
import { useGeoJsonData } from "../../GeoJsonDataContext";
import { useState, useEffect } from "react";
// import { isMobile } from "react-device-detect";

const Header = () => {
  const MEDIA_URL = process.env.REACT_APP_MEDIA_URL;
  const { geoJsonData } = useGeoJsonData();
  const [latestCount, setLatestCount] = useState(null);
  const [counterText, setCounterText] = useState(null);
  const [animatedValue, setAnimatedValue] = useState(0);

  // Function to animate from start to end value
  const animateValue = (start, end, duration) => {
    const range = end - start;
    let current = start;
    const increment = end > start ? 1 : -1;
    const stepTime = Math.abs(Math.floor(duration / range));
    const timer = setInterval(() => {
      current += increment;
      setAnimatedValue(current);
      if (current === end) {
        clearInterval(timer);
      }
    }, stepTime);
  };

  // Use the useEffect hook to animate whenever "end" changes
  useEffect(() => {
    if (latestCount) {
      animateValue(0, latestCount, 1000);
    }
  }, [latestCount]);

  useEffect(() => {
    if (geoJsonData) {
      let latestDate = null;
      let count = 0;

      const dateCounts = new Map();

      geoJsonData.features.forEach((feature) => {
        const { date } = feature.properties;
        // Update latest date if this date is greater
        if (!latestDate || date > latestDate) {
          latestDate = date;
        }

        // Format date as dd/mm/yy for counting
        const dateStr = `${String(date.getDate()).padStart(2, "0")}/${String(
          date.getMonth() + 1
        ).padStart(2, "0")}/${String(date.getFullYear()).slice(-2)}`;

        // Count the occurrences of each date
        dateCounts.set(dateStr, (dateCounts.get(dateStr) || 0) + 1);
      });

      // Convert latest date to dd/mm/yy format
      const latestDateStr = latestDate
        ? `${String(latestDate.getDate()).padStart(2, "0")}/${String(
            latestDate.getMonth() + 1
          ).padStart(2, "0")}/${String(latestDate.getFullYear()).slice(-2)}`
        : "";

      // Get the count of features with the latest date
      count = dateCounts.get(latestDateStr) || 0;

      setLatestCount(count);
      setCounterText(
        `messages added on ${latestDateStr} (total ${geoJsonData.features.length.toLocaleString()})`
      );
    }
  }, [geoJsonData]);

  return (
    <div className={styles.Header}>
      <h1 className={styles.heading}>
        World Teachers’ Day 2024 Interactive Map
      </h1>
      <div className={styles.callToAction}>
        <p
          id="messageButtonHelperText"
          className={styles.instruction + " " + styles.disabled}
        >
          Share your appreciation
        </p>
        <a
          className={styles.button + " " + styles.disabled}
          aria-describedby="messageButtonHelperText"
          aria-label="Submit a message to show your appreciation to teachers in NSW"
          href={process.env.REACT_APP_FORM_URL}
          target="_blank"
          rel="noreferrer"
        >
          Message
        </a>
        {counterText && animatedValue !== 0 && animatedValue && (
          <p className={styles.counter}>
            <strong>{animatedValue}</strong> {counterText}
          </p>
        )}
      </div>
      <img
        className={styles.motif}
        id={styles.twibbonMotif}
        src={MEDIA_URL + "/img/WTD2024-Twibbon.svg"}
        alt="icons and hashtag #WTD2024"
      />
    </div>
  );
};
export default Header;
