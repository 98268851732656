import React, { createContext, useContext, useState } from 'react';

const GeoJsonDataContext = createContext();

export const useGeoJsonData = () => {
  return useContext(GeoJsonDataContext);
};

export const GeoJsonDataProvider = ({ children }) => {
  const [geoJsonData, setGeoJsonData] = useState(null);

  return (
    <GeoJsonDataContext.Provider value={{ geoJsonData, setGeoJsonData }}>
      {children}
    </GeoJsonDataContext.Provider>
  );
};
