import './App.scss';

import { useState } from 'react';
// import { styles } from './App.scss';

import { GeoJsonDataProvider } from './GeoJsonDataContext';
import { DataLoaderHelper } from './DataLoaderHelper';

import { Tabs, Tab } from 'react-bootstrap';

import Header from './components/Header/Header';
import Map from './components/Map/Map';
import Table from './components/Table/Table';

function App() {

  const [activeTab, setActiveTab] = useState('map');

  return (
    <GeoJsonDataProvider>
      <DataLoaderHelper />
      <div id="wtd2023InteractiveMap" className="App">
        <Header />
        <Tabs
          id="controlled-tab"
          activeKey={activeTab}
          onSelect={(k) => setActiveTab(k)}
        >
          <Tab eventKey="map" title="Map">
            {activeTab === 'map' && <Map />}
          </Tab>
          <Tab eventKey="table" title="Table">
            {activeTab === 'table' && <Table />}
          </Tab>
        </Tabs>
      </div>
    </GeoJsonDataProvider>
  );
}

export default App;
