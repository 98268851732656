import { useState, useEffect } from "react";
import styles from "./Table.module.scss";
import { useGeoJsonData } from "../../GeoJsonDataContext";
import Filter from "../Filter/Filter";
import { isMobile as isMobileFromUserAgent } from "react-device-detect";
const isMobile = isMobileFromUserAgent || window.innerWidth <= 768;

const Table = () => {
  const { geoJsonData } = useGeoJsonData();
  const [filteredData, setFilteredData] = useState([]);
  const [tableLoading, setTableLoading] = useState(true);

  const handleInputChange = (selectedOption) => {
    if (geoJsonData) {
      if (!selectedOption || selectedOption.value === "all") {
        setFilteredData(geoJsonData.features);
      } else {
        setFilteredData(
          geoJsonData.features.filter(
            (feature) =>
              feature.properties.school
                .toLowerCase()
                .includes(selectedOption.value.toLowerCase()) ||
              (feature.properties.postcode &&
                feature.properties.postcode.includes(selectedOption.value))
          )
        );
      }
      setTableLoading(false);
    }
  };

  useEffect(() => {
    if (geoJsonData) {
      setFilteredData(
        geoJsonData.features.sort((a, b) => {
          const dateA = a.properties.date;
          const dateB = b.properties.date;
          return dateB - dateA;
        })
      );
      setTableLoading(false);
    }
  }, [geoJsonData]);

  const generateOptions = (data) => {
    const schools = new Set(); // For unique school names
    const postcodes = new Set(); // For unique postcodes

    geoJsonData.features.forEach((feature) => {
      schools.add(feature.properties.school);
      postcodes.add(feature.properties.postcode);
    });

    const createOption = (value) => ({
      value: value,
      label: value === "" ? "Not specified" : value,
    });

    // Map and sort school options
    const schoolOptions = Array.from(schools)
      .filter((item) => item)
      .map(createOption)
      .sort((a, b) => a.label.localeCompare(b.label));

    // Map and sort postcode options
    const postcodeOptions = Array.from(postcodes)
      .filter((item) => item)
      .map(createOption)
      .sort((a, b) => a.label.localeCompare(b.label));

    // Combine into grouped options
    const options = [
      {
        label: "Schools",
        options: schoolOptions,
      },
      {
        label: "Postcodes",
        options: postcodeOptions,
      },
    ];
    options.unshift({ value: "all", label: "All Schools" });
    return options;
  };

  function daysAgo(date) {
    const now = new Date();
    const timeDifference = now - date; // Difference in milliseconds
    const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));

    if (daysDifference === 0) {
      return "today";
    } else if (daysDifference === 1) {
      return "yesterday";
    } else if (daysDifference > 10) {
      const day = String(date.getDate()).padStart(2, "0");
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const year = String(date.getFullYear()).slice(2);
      return `${day}/${month}/${year}`;
    } else {
      return `${daysDifference} days ago`;
    }
  }

  return (
    geoJsonData && (
      <div className={styles.tableContainer}>
        <Filter
          options={generateOptions(geoJsonData)}
          onInputChange={handleInputChange}
        />
        <table className={styles.Table} tabIndex="0">
          <caption>Messages for teachers from across New South Wales</caption>
          <thead>
            <tr>
              <th id="messageMessage" scope="col">
                Message
              </th>
              <th id="messageSchool" scope="col">
                School
              </th>
              {!isMobile && (
                <th id="messagePostcode" scope="col">
                  From postcode
                </th>
              )}
              {!isMobile && (
                <th id="messageDate" scope="col">
                  Date
                </th>
              )}
            </tr>
          </thead>
          <tbody>
            {filteredData.map((entry, index) => (
              <tr key={index}>
                <td headers="messageMessage">
                  <span className={`${styles.message}`}>
                    {entry.properties.message}
                  </span>
                  {isMobile && (
                    <span className={`${styles.date}`}>
                      Date: {daysAgo(entry.properties.date)}
                    </span>
                  )}
                </td>
                <td headers="messageSchool">
                  <span className={`${styles.school}`}>
                    {entry.properties.school}
                  </span>
                  {isMobile && (
                    <span className={`${styles.postcode}`}>
                      Postcode: {entry.properties.postcode}
                    </span>
                  )}
                </td>
                {!isMobile && (
                  <td headers="messagePostcode">{entry.properties.postcode}</td>
                )}
                {!isMobile && (
                  <td headers="messageDate">
                    {daysAgo(entry.properties.date)}
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        </table>
        {tableLoading && (
          <div className={styles.overlay}>
            <div className={styles.spinner}></div>
          </div>
        )}
      </div>
    )
  );
};
export default Table;
