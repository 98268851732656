import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';

function getEl(embedSelector) {
  if (typeof embedSelector === "string") {
      const el = document.querySelector(embedSelector);
      // if (!el) throw new Error(`No div matching selector "${embedSelector}"`);
      if (!el) return;
      return el;
  } else {
      return embedSelector;
  }
}

const root = ReactDOM.createRoot(getEl("[data-app-embed]"));

window.addEventListener("DOMContentLoaded", () => {
  if (getEl("[data-app-embed]")) {
    root.render(<App />);
  }
});
