// FilterControl.js
import React from 'react';
import ReactDOM from 'react-dom/client';
import Filter from './Filter';

class FilterControl {
  constructor(options, onInputChange) {
    this.options = options;
    this.onInputChange = onInputChange;
  }

  onAdd(map) {
    this.map = map;
    this.container = document.createElement('div');

    // Create a root and render the component into it
    const root = ReactDOM.createRoot(this.container);
    root.render(<Filter options={this.options} onInputChange={this.onInputChange} />);
    
    return this.container;
  }

  onRemove() {
    // Clean up on removal
    ReactDOM.unmountComponentAtNode(this.container);
    this.map = undefined;
  }
}

export default FilterControl;
